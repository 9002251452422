/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

 module.exports = {
  logo: 'candid.svg',
  logoalt: 'candid-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_candid.scss',
  name: 'Candid Platform',
  clientName: 'candid',
  phone: '+31 85 018 4600',
  email: 'info@candidplatform.com',
  website: 'https://www.candidplatform.com/en/',
  glossary: true,
  // langFileName: 'nlc.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#fa7850',
      color2: '#ffe49a',
      color3: '#e36d49',
      color4: '#ffb07d',
    },
  },
  language: 'en',
  showAnimations: false,
  showAssetDescription: true,
};
